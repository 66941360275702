export const TRANSLATIONS_EN = {
    navbar: {
        home: "Home",
        news: "News",
        howToBuy: "How to buy",
        faq: "FAQ",
        meetTheTeam: "Meet the team",
        tutorials: "Tutorials",
        joinUs: "Join Us!",
        paper: "Read Whitepaper",
        menu: "MENU",
    },
    faq: {
        title: "Frequently Asked Questions",
    }
};
