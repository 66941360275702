import { observer } from "mobx-react";
import React, { useEffect } from 'react';
import Articles from "../../components/articles";
import Faq from "../../components/faq";
import FirstTime from "../../components/first-time";
import Footer from "../../components/footer";
import Header from "../../components/header";
import HowToBuy from "../../components/how-to-buy";
import JoinUs from "../../components/join-us";
import LatestNews from "../../components/latest-news";
import { useStores } from "../../stores/RootStore";
import { default as commonStyles } from "./index.module.scss";

const Home = observer(() => {
    const { uiStore } = useStores();
    const { setHomeMounted } = uiStore;

    useEffect(() => {
        setHomeMounted(true);
        return () => { setHomeMounted(false); };
    });
    return (
        <div className={commonStyles["header-background"]}>
            <div className={commonStyles["container"]}>
                <Header />
                <Articles />
                <LatestNews />
                <HowToBuy />
                <JoinUs />
                <FirstTime />
                <Faq />
                <Footer />
            </div>
        </div>
    )
});

export default Home;
