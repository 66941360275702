import { observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

const MeetTheTeam = observer(() => {
    const { t } = useTranslation();
    const [teamArray, setTeamArray] = useState();

    useEffect(() => {
        fetch("https://api.nftendo.net/api/teams?populate=*")
            .then(response => response.json())
            .then(data => { setTeamArray(data.data) })
    }, [])

    return (

        <div id="team" className={styles["team"]}>
            <h2 data-aos="fade-down">
                Meet The Team
            </h2>
            <div className={styles["team__container"]}>
                <div className={styles["team__members"]}>
                    {teamArray && teamArray.map((item, i) => {
                        return (
                            item.attributes.Linkedin && item.attributes.Description1 &&
                            <div key={i} data-aos="fade-down" data-aos-delay={i * 100} className={styles["team__members-member"]}>
                                <img src={`https://api.nftendo.net${item.attributes.Image.data.attributes.formats.thumbnail.url}`} alt="Profile" className={styles["team__members-profile"]} />
                                <span className={styles["team__members-name"]}>
                                    {item.attributes.Name}
                                </span>
                                <span className={styles["team__members-position"]}>
                                    {item.attributes.Position}
                                </span>
                                <a href={item.attributes.Linkedin} className={styles["team__members-linkedin"]}>
                                    <img src={"images/linkedin.svg"} alt="Linkedin icon" className={styles["team__members-linkedin"]} rel="noopener noreferrer" target="_blank" />
                                </a>
                                <span className={styles["team__members-description1"]}>
                                    {item.attributes.Description1}
                                </span>
                                <span className={styles["team__members-description2"]}>
                                    {item.attributes.Description2}
                                </span>
                            </div>
                        )
                    })}
                </div>
            </div>
            <h2 data-aos="fade-right">
                Contributors
            </h2>
            <div className={styles["team__container"]}>
                <div className={styles["team__members"]}>
                    {teamArray && teamArray.map((item, i) => {
                        return (
                            !item.attributes.Linkedin &&
                            <div key={i} data-aos="fade-right" data-aos-delay={i * 100} className={styles["team__members-member"]}>
                                <img src={`https://api.nftendo.net${item.attributes.Image.data.attributes.formats.thumbnail.url}`} alt="Profile" className={styles["team__members-profile"]} />
                                <span className={styles["team__contributors-name"]}>
                                    {item.attributes.Name}
                                </span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div >
    )
});

export default MeetTheTeam;
