import { observer } from "mobx-react";
import React, { useEffect } from 'react';
import Footer from "../../components/footer";
import MeetTheTeam from "../../components/meet-the-team";
import { useStores } from "../../stores/RootStore";
import { default as commonStyles } from "./index.module.scss";

const Team = observer(() => {
    const { uiStore } = useStores();
    const { setHomeMounted } = uiStore;

    useEffect(() => {
        setHomeMounted(true);
        return () => { setHomeMounted(false); };
    });
    return (
        <div className={commonStyles["header-background"]}>
            <div className={commonStyles["container"]}>
                <MeetTheTeam />
                <Footer />
            </div>
        </div>
    )
});

export default Team;
