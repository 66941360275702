import { observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from "./index.module.scss";

const JoinUs = observer(() => {
    const { t } = useTranslation();
    const [cexes, setCexes] = useState();

    useEffect(() => {
        fetch("https://api.nftendo.net/api/cexes?populate=*")
            .then(response => response.json())
            .then(data => { setCexes(data) })
    }, [])

    return (
        <div id="join-us" className={styles["join-us__container"]}>
            <span data-aos="fade-right">Join us</span>
            <div className={styles["join-us__wrapper"]}>
                <div data-aos="fade-down" data-aos-delay="100" className={styles["join-us"]}>
                    {cexes && cexes.data.map((item, i) => {
                        return (
                            <a key={i} href={item.attributes.Link}>
                                <img src={`https://api.nftendo.net${item.attributes.Image.data.attributes.formats.thumbnail.url}`} alt="Article" />
                            </a>
                        )
                    })}
                </div>
            </div>
        </div>
    )
});

export default JoinUs;
