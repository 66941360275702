const ScrollLinkEnum = Object.freeze({
    HOME: "HOME",
    NEWS: "NEWS",
    HOWTOBUY: "HOWTOBUY",
    FAQ: "FAQ",
    MEETTHETEAM: "MEETTHETEAM",
    TUTORIALS: "TUTORIALS",
    JOINUS: "JOINUS",
    NONE: "NONE"
})

export default ScrollLinkEnum;
