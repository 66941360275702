import { observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from "./index.module.scss";

const FirstTime = observer(() => {
    const { t } = useTranslation();
    const [cexes, setCexes] = useState();
    useEffect(() => {
        fetch("https://api.nftendo.net/api/cexes?populate=*")
            .then(response => response.json())
            .then(data => { setCexes(data) })
    }, [])

    return (
        <div id="first-time" className={styles["first-time__container"]}>
            <h2 data-aos="fade-right">First Time?</h2>
            <div data-aos="fade-down" className={styles["first-time__wrapper"]}>
                <img className={styles["first-time__wrapper-token-img-1"]} src="images/coin-3d.png" alt="Coin 3d" />
                <img className={styles["first-time__wrapper-token-img-2"]} src="images/coin-3d.png" alt="Coin 3d" />
                <div className={styles["first-time__stickers-container"]}>
                    <span data-aos="fade-down" className={styles["first-time__title"]}>Using Uniswap and Metamask</span>
                    <div className={styles["first-time__stickers-wrapper"]}>
                        <div data-aos="fade-right" data-aos-delay="100" className={styles["first-time__stickers-sticker"]}>
                            <span className={styles["first-time__stickers-sticker-number"]}>1)
                            </span>
                            <img src="images/metamask-icon.png" alt="Metamask icon" />
                            <div className={styles["first-time__stickers-text-wrapper"]}>
                                <span className={styles["first-time__stickers-title"]}>Download Metamask</span>
                                <span className={styles["first-time__stickers-description"]}>Go to Metamask and load the app. Follow the in-app-tutorial, log in and get started. Don't forget to write down your private pass-phrase (don't lose it!).</span>
                            </div>
                            <a href="https://metamask.io/download.html" className={styles["button"]} style={{ background: "#ffa200" }} rel="noopener noreferrer" target="_blank">
                                Download Metamask
                            </a>
                        </div>
                        <div href="https://coinbase.com/" data-aos="fade-down" data-aos-delay="200" className={styles["first-time__stickers-sticker"]}>
                            <span className={styles["first-time__stickers-sticker-number"]}>2)
                            </span>
                            <img src="images/eth-icon.png" alt="Metamask icon" />
                            <div className={styles["first-time__stickers-text-wrapper"]}>
                                <span className={styles["first-time__stickers-title"]}>Buy Ethereum</span>
                                <span className={styles["first-time__stickers-description"]}>Get some ETH at your preferred exchange or use link below and buy on Coinbase</span>
                            </div>
                            <a href="https://www.coinbase.com/price/akita-inu" className={styles["button"]} style={{ background: "#ffa200" }} rel="noopener noreferrer" target="_blank">
                                Buy on Coinbase
                            </a>
                        </div>
                        <div data-aos="fade-left" data-aos-delay="300" className={styles["first-time__stickers-sticker"]}>
                            <span className={styles["first-time__stickers-sticker-number"]}>3)
                            </span>
                            <img src="images/transfer-icon.png" alt="Metamask icon" />
                            <div className={styles["first-time__stickers-text-wrapper"]}>
                                <span className={styles["first-time__stickers-title"]}>Transfer to Metamask</span>
                                <span className={styles["first-time__stickers-description"]}>Copy your Metamask address and transfer ETH to your wallet.</span>
                            </div>
                            <a href="https://wallet.coinbase.com/faq/" className={styles["button"]} style={{ background: "#ffa200" }} rel="noopener noreferrer" target="_blank">
                                How to withdraw from Coinbase
                            </a>
                        </div>
                        <div data-aos="fade-right" data-aos-delay="400" className={styles["first-time__stickers-sticker"]}>
                            <span className={styles["first-time__stickers-sticker-number"]}>4)
                            </span>
                            <img src="images/uniswap-icon.png" alt="Metamask icon" />
                            <div className={styles["first-time__stickers-text-wrapper"]}>
                                <span className={styles["first-time__stickers-title"]}>Connect to Uniswap</span>
                                <div className={styles["first-time__stickers-description"]}>
                                    <div className={styles["first-time__stickers-description-contract"]}>
                                        <span>Find the official AKITA contract address</span>
                                        <a href="https://etherscan.io/token/0x3301ee63fb29f863f2333bd4466acb46cd8323e6" rel="noopener noreferrer" target="_blank"> here</a>
                                    </div>
                                    (copy contract on top right corner) and connect your wallet to Uniswap.
                                </div>
                            </div>
                            <a href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x3301ee63fb29f863f2333bd4466acb46cd8323e6" className={styles["button"]} style={{ background: "#ffa200" }} rel="noopener noreferrer" target="_blank">
                                Swap Eth/Akita
                            </a>
                        </div>
                        <div data-aos="fade-up" data-aos-delay="500" className={styles["first-time__stickers-sticker"]}>
                            <span className={styles["first-time__stickers-sticker-number"]}>5)
                            </span>
                            <img src="images/swap-icon.png" alt="Metamask icon" />
                            <div className={styles["first-time__stickers-text-wrapper"]}>
                                <span className={styles["first-time__stickers-title"]}>Swap</span>
                                <div className={styles["first-time__stickers-description"]}>
                                    <span>Select From: ETH; To: AKITA</span>
                                    <span>Put in the ETH-amount you wish to swap. Make sure you got enough ETH for the transaction fee (edit the fee to make sure transaction runs smoothly).</span>
                                </div>
                            </div>
                        </div>
                        <div data-aos="fade-left" data-aos-delay="600" className={styles["first-time__stickers-sticker"]}>
                            <span className={styles["first-time__stickers-sticker-number"]}>6)
                            </span>
                            <img src="images/liquidity-icon.png" alt="Metamask icon" />
                            <div className={styles["first-time__stickers-text-wrapper"]}>
                                <span className={styles["first-time__stickers-title"]}>Add Liquidity</span>
                                <span className={styles["first-time__stickers-description"]}>If you wish, add liquidity to the ETH-AKITA pair on Uniswap.</span>
                            </div>
                            <a href="https://v2.info.uniswap.org/token/0x3301ee63fb29f863f2333bd4466acb46cd8323e6" className={styles["button"]} style={{ background: "#ffa200" }} rel="noopener noreferrer" target="_blank">
                                Add Liquidity
                            </a>
                        </div>
                    </div>
                </div>
                <div className={styles["first-time__cexes-container"]}>
                    <div data-aos="fade-down" data-aos-delay="600" className={styles["first-time__title"]}>
                        <span>Buy on CEX </span>
                        <span className={styles["first-time__title-cex"]}>
                            (centralized exchange)
                        </span>
                    </div>
                    <div data-aos="fade-left" data-aos-delay="700" className={styles["first-time__cexes-wrapper"]}>
                        <div className={styles["first-time__cexes"]}>
                            <div className={styles["first-time__cexes-wrapper-list"]}>
                                <span>1) Pick one of Exchanges where Akita is listed.</span>
                                <span>2) Create account on chosen exchange</span>
                                <span>3) Load Money on your exchange account</span>
                                <span>4) Buy Akita tokens</span>
                                <div className={styles["first-time__cexes-title"]}>Pick CEX:</div>
                                <div className={styles["first-time__cexes-image-wrapper"]}>
                                    {cexes && cexes.data.map((item, i) => {
                                        return (
                                            <a key={i} href={item.attributes.Link} rel="noopener noreferrer" target="_blank">
                                                <img src={`https://api.nftendo.net${item.attributes.Image.data.attributes.formats.thumbnail.url}`} alt="Article" />
                                            </a>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
});

export default FirstTime;
