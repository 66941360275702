import { observer } from "mobx-react";
import React from 'react';
import { NavLink } from "react-router-dom";
import ButtonTypeEnum from "../../enums/button-type-enum";
import { useStores } from "../../stores/RootStore";
import styles from "./index.module.scss";

const Button = observer((props) => {
    const { uiStore } = useStores();
    const { setScrollLink, scrollLink } = uiStore;


    return (
        props.type === ButtonTypeEnum.NAVLINK ?
            props.type === ButtonTypeEnum.NAVLINK &&
            <NavLink
                onClick={() => { props.function && props.function(); setScrollLink(props.scrollLink) }} className={props.path.includes(scrollLink) ? styles["button--active"] : styles["button"]} to={props.path} >
                <span>{props.label}</span>
            </NavLink >
            :
            props.type === ButtonTypeEnum.LINK1 ?
                props.type === ButtonTypeEnum.LINK1 &&
                <a className={styles["button"]} href={props.path} rel="noopener noreferrer" target="_blank" >
                    <span>{props.label}</span>
                </a >
                :
                props.type === ButtonTypeEnum.LINK2 &&
                <a className={styles["button2"]} href={props.path} rel="noopener noreferrer" target="_blank">
                    <span>{props.label}</span>
                </a >
    )
});

export default Button;
