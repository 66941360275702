import { observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from "./index.module.scss";

const Articles = observer(() => {
    const { t } = useTranslation();
    const [tickerArray, setTickerArray] = useState();

    useEffect(() => {
        fetch("https://api.nftendo.net/api/tickers?populate=*")
            .then(response => response.json())
            .then(data => { setTickerArray(data) })
    }, [])

    return (
        <div id="info" className={styles["info__container"]}>
            <span data-aos="fade-right">As Seen On</span>
            <div className={styles["info__wrapper"]}>
                <div data-aos="fade-down" data-aos-delay="100" className={styles["info"]}>
                    {tickerArray && tickerArray.data.map((item, i) => {
                        return (
                            <a key={i} href={item.attributes.Link} rel="noopener noreferrer" target="_blank">
                                <img src={`https://api.nftendo.net${item.attributes.Image.data.attributes.formats.thumbnail.url}`} alt="Article" />
                            </a>
                        )
                    })}
                </div>
            </div>
        </div >
    )
});

export default Articles;
