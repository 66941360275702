import { observer } from "mobx-react";
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from "./index.module.scss";

const HowToBuy = observer(() => {
    const { t } = useTranslation();

    return (
        <div id="how-to-buy" className={styles["how-to-buy__container"]}>
            <div className={styles["how-to-buy__background"]} />
            <h2 data-aos="fade-right">How to buy on Uniswap?</h2>
            <div data-aos="fade-left" data-aos-delay="100" className={styles["how-to-buy__label"]}>
                <span>Make sure you past in the correct </span>
                <a href="https://etherscan.io/token/0x3301ee63fb29f863f2333bd4466acb46cd8323e6" rel="noopener noreferrer" target="_blank">address</a>.
            </div>
            <div className={styles["how-to-buy__stickers-wrapper"]}>
                <div data-aos="fade-down" data-aos-delay="200" className={styles["how-to-buy__stickers"]}>
                    <img alt="Uniswap icon" src={"images/uniswap.png"} />
                </div>
                <span data-aos="fade-down" data-aos-delay="300" className={styles["how-to-buy__line"]} />
                <div data-aos="fade-down" data-aos-delay="400" className={styles["how-to-buy__stickers"]}>
                    <div className={styles["how-to-buy__stickers-sticker"]}>
                        <div className={styles["how-to-buy__stickers-text-wrapper"]}>
                            <span className={styles["how-to-buy__stickers-title"]}>Connect your wallet & Swap ETH/AKITA</span>
                            <span className={styles["how-to-buy__stickers-description"]}>Make sure you adapt to price slippage and got enough ETH for the fees in your wallet.</span>
                        </div>
                        <a className={styles["button"]} style={{ background: "#ffa200" }} href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x3301ee63fb29f863f2333bd4466acb46cd8323e6" rel="noopener noreferrer" target="_blank">
                            Buy on Uniswap
                        </a>
                    </div>
                </div>
                <span data-aos="fade-down" data-aos-delay="500" className={styles["how-to-buy__line"]} />
                <div data-aos="fade-down" data-aos-delay="600" className={styles["how-to-buy__stickers"]}>
                    <div className={styles["how-to-buy__stickers-sticker"]}>
                        <div className={styles["how-to-buy__stickers-text-wrapper"]}>
                            <span className={styles["how-to-buy__stickers-title"]}>Add Liquidity</span>
                            <span className={styles["how-to-buy__stickers-description"]}>Make Would you like to add liquidity to Uniswap Pool?</span>
                        </div>
                        <a href="https://v2.info.uniswap.org/token/0x3301ee63fb29f863f2333bd4466acb46cd8323e6" className={styles["button"]} style={{ background: "#ff007a" }} rel="noopener noreferrer" target="_blank">
                            Add Liquidity
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
});

export default HowToBuy;
