import { observer } from "mobx-react";
import React from 'react';
import styles from "./index.module.scss";

const Footer = observer(() => {
  return (
    <div className={styles["footer"]}>
      <div className={styles["footer__links-wrapper"]}>
        <div className={styles["footer__info-bg"]} />
        <span data-aos="fade-right" className={styles["footer__info"]}>
          Please Respect the Community.There is no AKITA Support team or Assistance team. Never give out your seed phrase or private keys to anyone. Always do your own research. This is not financial advice.
        </span>
        <div data-aos="fade-up" className={styles["footer__links"]}>
          <div data-aos-delay="100" className={styles["footer__links-col"]}>
            <span className={styles["footer__links-title"]}>Join the Community</span>
            <div className={styles["footer__links-list"]}>
              <a href="https://t.me/akitatoken">Main Akita Telegram</a>
              <a href="https://discord.gg/v85jGJUrnz">Discord</a>
              <a href="https://twitter.com/akita_network/">Twitter</a>
              <a href="https://www.instagram.com/akita_network/?hl=en">Instagram</a>
              <a href="https://www.facebook.com/AKITATOKEN">Facebook</a>
              <a href="https://www.youtube.com/channel/UCkTih23L5dcmoR6pBuT8t6A">Youtube</a>
            </div>
          </div>
          <div data-aos-delay="200" className={styles["footer__links-col"]}>
            <span className={styles["footer__links-title"]}>Information and news</span>
            <div className={styles["footer__links-list"]}>
              <a href="https://akitanetwork.medium.com/">Medium</a>
              <a href="https://github.com/Polarfox-DEX">Github</a>
              <a href="https://etherscan.io/token/0x3301ee63fb29f863f2333bd4466acb46cd8323e6">Etherscan</a>
              <a href="https://coinmarketcap.com/currencies/akita-inu/">CoinMarketCap</a>
              <a href="https://www.coingecko.com/en/coins/akita-inu">CoinGecko</a>
              <a href="https://www.dextools.io/app/ether/pair-explorer/0xda3a20aad0c34fa742bd9813d45bbf67c787ae0b">Dextools</a>
            </div>
          </div>
          <div data-aos-delay="300" className={styles["footer__links-col"]}>
            <div className={styles["footer__links-list"]}>
              <span className={styles["footer__links-title"]}>Exchanges:</span>
              <a href="https://coinmarketcap.com/currencies/akita-inu/markets/">On Ethereum</a>
              <a href="https://traderjoexyz.com/#/home">On Avalanche</a>
              <div className={styles["footer__links-address-wrapper"]}>
                <span className={styles["footer__links-address-title"]}>Contract Address for Ethereum</span>
                <a href="https://etherscan.io/token/0x3301ee63fb29f863f2333bd4466acb46cd8323e6" className={styles["footer__links-address"]}>0x3301ee63fb29f863f2333bd4466acb46cd8323e6</a>
              </div>
              <div className={styles["footer__links-address-wrapper"]}>
                <span className={styles["footer__links-address-title"]}>Contract Address for Avalanche</span>
                <a href="https://traderjoexyz.com/#/pool/AVAX/0xcaf5191fc480f43e4df80106c7695eca56e48b18" className={styles["footer__links-address"]}>0xcaf5191fc480f43e4df80106c7695eca56e48b18</a>
              </div>
            </div>
          </div>
          <img data-aos-delay="400" className={styles["footer__coin"]} src="images/coin.png" alt="ETH icon" />
        </div>
      </div>
    </div>
  )
});

export default Footer;
