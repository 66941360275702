import { observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from "./index.module.scss";

const LatestNews = observer(() => {
    const { t } = useTranslation();
    const [newsArray, setNewsArray] = useState();

    useEffect(() => {
        fetch("https://api.nftendo.net/api/news?populate=*")
            .then(response => response.json())
            .then(data => { setNewsArray(data.data.reverse()) })
    }, [])


    return (
        <div id="news" className={styles["news__wrapper"]}>
            <h2 data-aos="fade-right">Latest News</h2>
            <div className={styles["news__container"]}>
                <div data-aos="fade-right" data-aos-delay="100" className={styles["news__image-wrapper"]}>
                    <img src={"images/akita-news.svg"} alt="Akita News" />
                </div>
                <div data-aos="fade-left" data-aos-delay="200" className={styles["news__news-container"]}>
                    <div className={styles["news__news-wrapper"]}>
                        {newsArray && newsArray.map((item, i) => {
                            const date = new Date(item.attributes.createdAt).toLocaleString();
                            return (
                                <div key={i} className={styles["news"]} >
                                    <div className={styles["news__description-wrapper"]}>
                                        <div className={styles["news__text-wrapper"]}>
                                            <div className={styles["news__title-wrapper"]}>
                                                <h3>{item.attributes.Title}</h3>
                                                <span>{date}</span>
                                            </div>
                                            <span className={styles["news__description"]}>{item.attributes.Description}</span>
                                        </div>
                                        <div className={styles["news__news-footer-wrapper"]}>
                                            <div className={styles["news__news-image-wrapper"]}>
                                                <img src={`https://api.nftendo.net${item.attributes.Image.data.attributes.formats.medium.url}`} alt={item.attributes.Image.data.attributes.alternativeText} />
                                            </div>
                                            <a className={styles["button"]} style={{ background: "#ffa200", marginTop: "30px" }}>
                                                Read More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
});

export default LatestNews;
