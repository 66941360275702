import { observer } from "mobx-react";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import ButtonTypeEnum from "../../enums/button-type-enum";
import ScrollLinkEnum from "../../enums/scroll-link-enum";
import { useStores } from "../../stores/RootStore";
import Button from "../button";
import styles from "./index.module.scss";

const NavigationBar = observer(() => {
    const { t } = useTranslation();
    const { uiStore } = useStores();
    const { hamburgerMenuOpen, switchHamburgerMenu, setScrollLink } = uiStore;

    return (
        <>
            <div className={styles["navbar__container"]}>
                <div className={styles["navbar__logo-wrapper"]}>
                    <NavLink onClick={() => setScrollLink(ScrollLinkEnum.NONE)} to="/">
                        <img className={styles["navbar__logo"]} alt={"Akita Network Logo"} src={"images/akita-logo.svg"} />
                        <img className={styles["navbar__logo-text"]} alt={"Akita Network Logo Text"} src={"images/akita-logo-text.svg"} />
                    </NavLink >
                </div>
                <div className={styles["navbar__links-wrapper"]}>
                    <div className={styles["navbar__links"]}>
                        <Button type={ButtonTypeEnum.NAVLINK} scrollLink={ScrollLinkEnum.HOME} label={t("navbar.home")} path="/" />
                        <Button type={ButtonTypeEnum.NAVLINK} scrollLink={ScrollLinkEnum.NEWS} label={t("navbar.news")} path="/#news" />
                        <Button type={ButtonTypeEnum.NAVLINK} scrollLink={ScrollLinkEnum.HOWTOBUY} label={t("navbar.howToBuy")} path="/#how-to-buy" />
                        <Button type={ButtonTypeEnum.NAVLINK} scrollLink={ScrollLinkEnum.FAQ} label={t("navbar.faq")} path="/#faq" />
                        <Button type={ButtonTypeEnum.NAVLINK} scrollLink={ScrollLinkEnum.MEETTHETEAM} label={t("navbar.meetTheTeam")} path="/meet-the-team" />
                        <Button type={ButtonTypeEnum.NAVLINK} scrollLink={ScrollLinkEnum.TUTORIALS} label={t("navbar.tutorials")} path="/#tutorials" />
                        <Button type={ButtonTypeEnum.NAVLINK} scrollLink={ScrollLinkEnum.JOINUS} label={t("navbar.joinUs")} path="/#join-us" />
                    </div>
                </div>
                <div className={styles["navbar__hamburger-menu"]} onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen); }}>
                    {t("navbar.menu")}
                </div>
                <div className={styles["navbar__paper-wrapper"]}>
                    <Button type={ButtonTypeEnum.LINK2} label={t("navbar.paper")} path="https://5d194e64-77f8-4aec-9bdd-523bc14b90ad.filesusr.com/ugd/0f2e14_4dd671ce71744cb0bd60c55506d91def.pdf" />
                </div>
            </div>
            {
                hamburgerMenuOpen ?
                    <div className={styles["navbar__hamburger-menu-dropdown"]}>
                        <div className={styles["responsive-links-container"]}>
                            <Button type={ButtonTypeEnum.NAVLINK} function={() => switchHamburgerMenu(!hamburgerMenuOpen)} scrollLink={ScrollLinkEnum.HOME} label={t("navbar.home")} path="/" />
                            <Button type={ButtonTypeEnum.NAVLINK} function={() => switchHamburgerMenu(!hamburgerMenuOpen)} scrollLink={ScrollLinkEnum.NEWS} label={t("navbar.news")} path="/#news" />
                            <Button type={ButtonTypeEnum.NAVLINK} function={() => switchHamburgerMenu(!hamburgerMenuOpen)} scrollLink={ScrollLinkEnum.HOWTOBUY} label={t("navbar.howToBuy")} path="/#how-to-buy" />
                            <Button type={ButtonTypeEnum.NAVLINK} function={() => switchHamburgerMenu(!hamburgerMenuOpen)} scrollLink={ScrollLinkEnum.FAQ} label={t("navbar.faq")} path="/#faq" />
                            <Button type={ButtonTypeEnum.NAVLINK} function={() => switchHamburgerMenu(!hamburgerMenuOpen)} scrollLink={ScrollLinkEnum.MEETTHETEAM} label={t("navbar.meetTheTeam")} path="/meet-the-team" />
                            <Button type={ButtonTypeEnum.NAVLINK} function={() => switchHamburgerMenu(!hamburgerMenuOpen)} scrollLink={ScrollLinkEnum.TUTORIALS} label={t("navbar.tutorials")} path="/#tutorials" />
                            <Button type={ButtonTypeEnum.NAVLINK} function={() => switchHamburgerMenu(!hamburgerMenuOpen)} scrollLink={ScrollLinkEnum.JOINUS} label={t("navbar.joinUs")} path="/#join-us" />
                        </div>
                    </div>
                    : null
            }
        </>
    )
});

export default NavigationBar;
