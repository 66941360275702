import { observer } from "mobx-react";
import Moralis from "moralis";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from "./index.module.scss";

const Header = observer(() => {
    const { t } = useTranslation();
    const [holders, setHolders] = useState(0);
    const [price, setPrice] = useState(0);
    const serverUrl = "https://6uve9a8eautm.usemoralis.com:2053/server";
    const appId = "i5hhNYPaoWsZaKztnLswGaaApND1CReAJOgOCXN2";

    const fetchData = async () => {
        try {
            Moralis.start({ serverUrl, appId });
            const options = {
                address: "0x3301ee63fb29f863f2333bd4466acb46cd8323e6",
                chain: "eth",
                exchange: "Uniswap"
            };
            const price = await ((await Moralis.Web3API.token.getTokenPrice(options)).usdPrice);
            setPrice(price);
            fetch("https://api.ethplorer.io/getTokenInfo/0x3301ee63fb29f863f2333bd4466acb46cd8323e6?apiKey=EK-2Di3Q-hSUzfJG-sJdfJ")
                .then(response => response.json())
                .then(data => setHolders(data.holdersCount));
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetchData();
        setInterval(() => {
            fetchData();
        }, 30000)
    }, [])

    return (
        <>
            <div id="header"
                className={styles["header"]}>
                <div className={styles["row"]}>
                    <div className={styles["header__column"]}>
                        <div className={styles["header__welcome-wrapper"]}>
                            <h2 data-aos="fade-right">AKITA NETWORK 秋田犬</h2>
                            <div className={styles["header__description"]}>
                                <div data-aos="fade-right" data-aos-delay="100" className={styles["header__description--gap"]}>
                                    <span className={styles["header__description--orange"]}>Akita Inu Token is a cryptocurrency</span>
                                    <span> that started as a meme.</span>
                                </div>
                                <div data-aos="fade-right" data-aos-delay="200" className={styles["header__description--gap"]}>
                                    <span>Through the support of the community</span>
                                    <span className={styles["header__description--bold"]}> we have bridged to Avalanche for project governance
                                    </span>
                                    <span> - empowering our community.</span>
                                </div>
                            </div>
                        </div>
                        <div data-aos="fade-right" data-aos-delay="300" className={styles["header__info-wrapper"]}>
                            <div className={styles["header__info"]}>
                                <span>$AKITA Price</span>
                                <span className={styles["header__info-description"]}>${price.toFixed(8)}</span>
                            </div>
                            <div className={styles["header__info"]}>
                                <span>Market Cap</span>
                                <span className={styles["header__info-description"]}>${Number((price * 100000000000000).toFixed(0)).toLocaleString()}</span>
                            </div>
                            <div className={styles["header__info"]}>
                                <span>Holders</span>
                                <span className={styles["header__info-description"]}>{Number(holders).toLocaleString()}</span>
                            </div>
                        </div>
                        <div data-aos="fade-right" data-aos-delay="400" className={styles["header__scan-buttons-wrapper"]}>
                            <div className={styles["header__scan-button"]}>
                                <a href="https://etherscan.io/token/0x3301ee63fb29f863f2333bd4466acb46cd8323e6" rel="noopener noreferrer" target="_blank">
                                    <img src={"images/eth.png"} alt="ETH icon" />
                                    <span>Show $AKITA on Etherscan</span>
                                </a>
                            </div>
                            <div className={styles["header__scan-button"]}>
                                <a href="https://traderjoexyz.com/#/pool/AVAX/0xcaf5191fc480f43e4df80106c7695eca56e48b18" rel="noopener noreferrer" target="_blank">
                                    <img src={"images/avax.png"} alt="ETH icon" />
                                    <span>Show $AKITA on Avascan</span>
                                </a>
                            </div>
                        </div>
                        <div data-aos="fade-right" data-aos-delay="500" className={styles["header__buy-buttons-wrapper"]}>
                            <div className={styles["header__buy-button-1"]}>
                                <a href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x3301ee63fb29f863f2333bd4466acb46cd8323e6" rel="noopener noreferrer" target="_blank">
                                    <span>BUY AKITA ON ETH</span>
                                    <span className={styles["header__buy-button-1-icon"]}></span>
                                </a>
                            </div>
                            <div className={styles["header__buy-button-2"]}>
                                <a href="https://traderjoexyz.com/#/trade?inputCurrency=0x130966628846BFd36ff31a822705796e8cb8C18D&outputCurrency=0xcaf5191fc480f43e4df80106c7695eca56e48b18" rel="noopener noreferrer" target="_blank">
                                    <span>BUY AKITA ON AVAX</span>
                                    <span className={styles["header__buy-button-2-icon"]}></span>
                                </a>
                            </div>
                        </div>
                        <div data-aos="fade-right" data-aos-delay="600" className={styles["header__socials-wrapper"]}>
                            <a href="https://t.me/akitatoken" rel="noopener noreferrer" target="_blank">
                                <img alt="Telegram icon" className={styles["social-icons"]} src={"images/telegram.png"} />
                            </a>
                            <a href="https://discord.gg/v85jGJUrnz" rel="noopener noreferrer" target="_blank">
                                <img alt="Discord icon" className={styles["social-icons"]} src={"images/discord.png"} />
                            </a>
                            <a href="https://twitter.com/AKITA_network" rel="noopener noreferrer" target="_blank">
                                <img alt="Twitter icon" className={styles["social-icons"]} src={"images/twitter.png"} />
                            </a>
                            <a href="https://akitanetwork.medium.com/" rel="noopener noreferrer" target="_blank">
                                <img alt="Medium icon" className={styles["social-icons"]} src={"images/medium.png"} />
                            </a>
                        </div>
                    </div>
                    <div data-aos="fade-left" data-aos-delay="600" className={styles["header__column"]}>
                        <div className={styles["header__akita-wrapper"]}>
                            <img className={styles["header__akita-img"]} src={"images/akita.png"} alt="Akita" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

export default Header;
